import axios from "axios";
import cookiesProvider from "../providers/cookies-provider";
import { getApiUrl } from "./urls";

export const uploadFileTransform = async (data, resource, field) => {
    let newData = data
    const apiUrl = getApiUrl()
    const files = newData[field].filter(file => !file.id)
    if (files.length > 0) {
        const token = cookiesProvider.getToken();
        const cfg = {
            headers: { Authorization: `Bearer ${token}` },
            "Content-Type": "multipart/form-data",
            'Access-Control-Allow-Origin': 'https://app.juristta.com'
        }
        let fd = new FormData()
        fd.append("resource", resource)

        files.map(async (file) => {
            fd.append("files", file.rawFile)
        })

        await axios.post(`${apiUrl}/attachments/upload`, fd, cfg).then(
            (res) => {
                if (res.status === 201 && res.data) {
                    newData[field] = res.data;
                    return { ...newData }
                }
            }
        )
    }
}