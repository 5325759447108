import { Apartment, Assessment, Assignment, Code, Gavel, MenuBook, School } from "@material-ui/icons"
import { academicPeriodResource } from "../../resources/academic-period"
import { activityTypeResource } from "../../resources/activity-types"
import { classResource } from "../../resources/classes"
import { clientResource } from "../../resources/clients"
import { externalServiceRequestResource } from "../../resources/external-service-requests"
import { guidelineTypeResource } from "../../resources/guideline-types"
import { institutionResource } from "../../resources/institutions"
import { partyResource } from "../../resources/parties"
import { partyTypeResource } from "../../resources/party-types"
import { processTypeResource } from "../../resources/process-types"
import { processResource } from "../../resources/processes"
import { profileResource } from "../../resources/profiles"
import { schedulingResource } from "../../resources/scheduling"
import { schoolActivitiesResource } from "../../resources/school-activities"
import { schoolModelActivitiesResource } from "../../resources/school-model-activities"
import { serviceRequestResource } from "../../resources/service-requests"
import { themeResource } from "../../resources/themes"
import { userResource } from "../../resources/users"
import { resourceProvider } from "../resource-provider/resource-provider"
import { institutionBranchResource } from "../../resources/institution-branches"

const JuristtaMenuItem = ({ id, label, icon, path, product, custom, children, profiles, superAdmin }) => {
    return { id, label, icon, path, product, custom, children, profiles, superAdmin }
}

const menus = [

    JuristtaMenuItem({
        id: 'stats',
        label: 'Indicadores',
        icon: Assessment,
        path: `/stats`,
        product: ['office'],
        custom: true,
        profiles: ['Professor', 'Administrador', 'Advogado', 'Coordenador', 'Secretaria']
    }),

    JuristtaMenuItem({
        id: 'menuSuperAdmin',
        label: 'Superadministrador',
        icon: Assignment,

        children: [

            JuristtaMenuItem({
                id: partyTypeResource.name,
                label: partyTypeResource.options.label,
                icon: partyTypeResource.icon,
                path: partyTypeResource.link,
                product: partyTypeResource.products,
                superAdmin: true,
            }),

            JuristtaMenuItem({
                id: themeResource.name,
                label: themeResource.options.label,
                icon: themeResource.icon,
                path: themeResource.link,
                product: themeResource.products,
                superAdmin: true,
            }),

            JuristtaMenuItem({
                id: profileResource.name,
                label: profileResource.options.label,
                icon: profileResource.icon,
                path: profileResource.link,
                product: profileResource.products,
                superAdmin: true,
            }),

            JuristtaMenuItem({
                id: institutionBranchResource.name,
                label: institutionBranchResource.options.label,
                icon: institutionBranchResource.icon,
                path: institutionBranchResource.link,
                product: institutionBranchResource.products,
                superAdmin: true,
            })
        ]
    }),

    JuristtaMenuItem({
        id: 'menuInstitutions',
        label: 'Institucional',
        icon: Apartment,
        children: [

            JuristtaMenuItem({
                id: institutionResource.name,
                label: institutionResource.options.label,
                icon: institutionResource.icon,
                path: institutionResource.link,
                product: institutionResource.products

            }),

            JuristtaMenuItem({
                id: userResource.name,
                label: userResource.options.label,
                icon: userResource.icon,
                path: userResource.link,
                product: userResource.products

            })
        ]
    }),

    JuristtaMenuItem({
        id: 'menuAcademic',
        label: 'Acadêmico',
        icon: School,
        children: [

            JuristtaMenuItem({
                id: academicPeriodResource.name,
                label: academicPeriodResource.options.label,
                icon: academicPeriodResource.icon,
                path: academicPeriodResource.link,
                product: academicPeriodResource.products
            }),

            JuristtaMenuItem({
                id: classResource.name,
                label: classResource.options.label,
                icon: classResource.icon,
                path: classResource.link,
                product: classResource.products
            }),

        ]
    }),

    JuristtaMenuItem({
        id: 'menuSchoolActivities',
        label: 'Atividades',
        icon: Gavel,
        children: [

            JuristtaMenuItem({
                id: schoolModelActivitiesResource.name,
                label: 'Matriz',
                icon: schoolModelActivitiesResource.icon,
                path: schoolModelActivitiesResource.link,
                product: schoolModelActivitiesResource.products

            }),

            JuristtaMenuItem({
                id: schoolActivitiesResource.name,
                label: 'Prática',
                icon: schoolActivitiesResource.icon,
                path: schoolActivitiesResource.link,
                product: schoolActivitiesResource.products

            }),

            JuristtaMenuItem({
                id: guidelineTypeResource.name,
                label: guidelineTypeResource.options.label,
                icon: guidelineTypeResource.icon,
                path: guidelineTypeResource.link,
                product: guidelineTypeResource.products

            }),

        ]
    }),

    JuristtaMenuItem({
        id: 'menuInstitutionParams',
        label: 'Parametrizações',
        icon: MenuBook,
        children: [

            JuristtaMenuItem({
                id: activityTypeResource.name,
                label: activityTypeResource.options.label,
                icon: activityTypeResource.icon,
                path: activityTypeResource.link,
                product: activityTypeResource.products

            }),

            JuristtaMenuItem({
                id: externalServiceRequestResource.name,
                label: externalServiceRequestResource.options.label,
                icon: externalServiceRequestResource.icon,
                path: externalServiceRequestResource.link,
                product: externalServiceRequestResource.products

            }),

            JuristtaMenuItem({
                id: processTypeResource.name,
                label: processTypeResource.options.label,
                icon: processTypeResource.icon,
                path: processTypeResource.link,
                product: processTypeResource.products

            }),
        ]
    }),


    JuristtaMenuItem({
        id: 'menuNPJ',
        label: 'NPJ',
        icon: Gavel,

        children: [
            JuristtaMenuItem({
                id: serviceRequestResource.name,
                label: serviceRequestResource.options.label,
                icon: serviceRequestResource.icon,
                path: serviceRequestResource.link,
                product: serviceRequestResource.products
            }),


            JuristtaMenuItem({
                id: schedulingResource.name,
                label: schedulingResource.options.label,
                icon: schedulingResource.icon,
                path: schedulingResource.link,
                product: externalServiceRequestResource.products
            }),

            JuristtaMenuItem({
                id: processResource.name,
                label: processResource.options.label,
                icon: processResource.icon,
                path: processResource.link,
                product: processResource.products

            }),

            JuristtaMenuItem({
                id: partyResource.name,
                label: partyResource.options.label,
                icon: partyResource.icon,
                path: partyResource.link,
                product: partyResource.products

            }),

            JuristtaMenuItem({
                id: clientResource.name,
                label: clientResource.options.label,
                icon: clientResource.icon,
                path: clientResource.link,
                product: clientResource.products

            }),


        ]
    }),

    JuristtaMenuItem({
        id: 'reports',
        label: 'Relatórios',
        icon: Assessment,
        path: '/reports',
        product: ['office', 'school'],
        custom: true,
        profiles: ['Administrador', 'Advogado', 'Coordenador', 'Secretaria', 'Professor']
    }),

    JuristtaMenuItem({
        id: 'menuTools',
        label: 'Material de apoio',
        icon: MenuBook,
        children: [

            JuristtaMenuItem({
                id: 'vadeMecum',
                label: 'Vade Mecum',
                icon: MenuBook,
                path: `/vade-mecum`,
                product: ['school', 'office'],
                custom: true,
                profiles: ['Professor', 'Administrador', 'Advogado', 'Coordenador', 'Secretaria', 'Aluno']
            }),

        ]
    }),

    JuristtaMenuItem({
        id: 'chatGpt',
        label: 'ChatGPT',
        icon: MenuBook,
        path: `/chat-gpt`,
        product: ['school', 'office'],
        custom: true,
        profiles: ['Professor', 'Administrador', 'Advogado', 'Coordenador', 'Secretaria', 'Aluno']
    }),


    JuristtaMenuItem({
        id: 'menuTutorials',
        label: 'Tutoriais',
        icon: MenuBook,
        children: [

            JuristtaMenuItem({
                id: 'schoolStudentTutorial',
                label: 'Aluno',
                icon: MenuBook,
                path: `/school-student-tutorial`,
                product: ['school'],
                custom: true,
                profiles: ['Aluno', 'Professor', 'Administrador', 'Advogado', 'Coordenador', 'Secretaria']
            }),

            JuristtaMenuItem({
                id: 'schoolManagerTutorial',
                label: 'Gestor',
                icon: MenuBook,
                path: `/school-manager-tutorial`,
                product: ['school'],
                custom: true,
                profiles: ['Professor', 'Administrador', 'Advogado', 'Coordenador', 'Secretaria']
            }),

            JuristtaMenuItem({
                id: 'officeStudentTutorial',
                label: 'Aluno',
                icon: MenuBook,
                path: `/office-student-tutorial`,
                product: ['office'],
                custom: true,
                profiles: ['Aluno', 'Professor', 'Administrador', 'Advogado', 'Coordenador', 'Secretaria']
            }),

            JuristtaMenuItem({
                id: 'officeManagerTutorial',
                label: 'Gestor',
                icon: MenuBook,
                path: `/office-manager-tutorial`,
                product: ['office'],
                custom: true,
                profiles: ['Professor', 'Administrador', 'Advogado', 'Coordenador', 'Secretaria']
            }),

        ]
    }),

    JuristtaMenuItem({
        id: 'menuDevelopers',
        label: 'Desenvolvedores',
        icon: Code,
        children: [
            JuristtaMenuItem({
                id: 'apiDocs',
                label: 'Documentação API',
                icon: Code,
                path: `/api-docs`,
                product: ['school', 'office'],
                custom: true,
                profiles: ['Administrador']
            }),
        ]
    }),

]

const shouldShow = (submenu, context) => {
    const currProduct = context?.product.id === 1 ? 'school' : 'office'
    const resource = resourceProvider.getResource(submenu.id)
    let resp = false;

    if (submenu.product.includes(currProduct)) {
        if (submenu.custom) {
            if (submenu.profiles.includes(context?.currentUser?.profiles[0]?.name)) {
                if (!submenu.superAdmin) { resp = true; }
            }
        } else {
            if (context?.permissions?.find(item => item?.resourceName === resource?.name)?.read === true) {
                if (!submenu.superAdmin) { resp = true; }
            }
        }
        if (context?.superAdmin) {
            resp = true;
        }
    }
    return resp;
}

export const menuLayoutProvider = {

    getAvailableMenus: (context) => {
        const newMenus = []
        for (const menu of menus) {
            let newSubmenus = [];
            if (menu.children) {
                for (const submenu of menu.children) {
                    if (shouldShow(submenu, context)) {
                        newSubmenus.push(submenu)
                    }
                }

                if (newSubmenus.length > 0) {
                    newMenus.push({ ...menu, children: newSubmenus })
                }
            } else {
                if (shouldShow(menu, context)) { newMenus.push(menu) }
            }

        }
        return newMenus;
    }
}