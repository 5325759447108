import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Link, Tooltip, Typography } from '@material-ui/core';
import { Explore } from '@material-ui/icons';
import React, { useState } from 'react';
import Carousel from 'react-material-ui-carousel';
import { useStyles } from './process-dashboard-styles';
import Vimeo from '@u-wave/react-vimeo';
import YouTube from '@u-wave/react-youtube';
import { DangerousHTMLDiv } from '../../components/dangerousHTMLDiv';

const ViewOrientationsDialog = ({ activity, small, ...props }) => {

    const [open, setOpen] = useState(false)
    const classes = useStyles();
    const isSmall = (small === undefined) ? true : small;

    const handleOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false);
    }

    return (
        <>
            <Tooltip title={'Orientações'}>
                {isSmall ?
                    <IconButton color={'text'} onClick={handleOpen}>
                        <Explore />
                    </IconButton>
                    :
                    <Button color={'text'} startIcon={<Explore />} onClick={handleOpen}>
                        Orientações
                    </Button>
                }
            </Tooltip>

            <Dialog open={open} onClose={handleClose} scroll={"paper"} fullWidth maxWidth={'sm'}>
                <DialogTitle className={classes.dialogTitle}>
                    Orientações - {activity?.type?.name}
                </DialogTitle>
                <DialogContent dividers={true}>
                    <DialogContentText>
                        <DangerousHTMLDiv html={activity?.type?.observations} />
                    </DialogContentText>
                    <Carousel autoPlay={false} navButtonsAlwaysVisible={activity?.type?.links?.length > 1}>
                        {
                            activity?.type?.links?.map((video, index) => {
                                if (video.source === 'youtube') return <YouTube modestBranding={true} showCaptions={false} showRelatedVideos={false} annotations={false} autoplay={false} video={video.id} id={index} height={'457px'} width={'100%'} />
                                if (video.source === 'vimeo') return <Vimeo video={video.id} id={index} responsive />
                                else return null;
                            })
                        }
                    </Carousel>
                    {activity?.type?.attachments?.length > 0 && <DialogContentText>
                        <Typography paragraph={true} >Anexos</Typography>
                    </DialogContentText>}
                    {
                        activity?.type?.attachments?.map((attachment, index) => {
                            return <p>
                                <Link href={attachment.path} target={'_blank'} > {attachment.name} </Link>
                            </p>
                        })
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>
                        Fechar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default ViewOrientationsDialog;