import React from "react";
import { BooleanInput, Edit, FormTab, required, TabbedForm, TextInput } from "react-admin";

const InstitutionBranchEdit = (props) => {

    return (
        <Edit {...props}>
            <TabbedForm redirect='list' mutationMode="pessimistic">
                <FormTab label={'Dados Gerais'} >
                    <TextInput source="institution.name" label={'Instituição'} fullWidth disabled />
                    <TextInput source="name" label="Nome" fullWidth validate={[required()]} />
                    <BooleanInput source="active" label="Ativo" />
                </FormTab>
            </TabbedForm>
        </Edit>
    )
}
export default InstitutionBranchEdit;