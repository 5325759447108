import { Step, StepButton, Stepper } from "@material-ui/core";
import RichTextInput from "ra-input-rich-text";
import React, { useState } from "react";
import { ArrayInput, BooleanInput, Button, Create, FileField, FileInput, required, SaveButton, SimpleForm, SimpleFormIterator, TextInput, Toolbar } from "react-admin";
import { Spacer } from "../../components/spacer";
import { VideoUrlInput } from "../../components/video-url-input";
import { uploadFileTransform } from "../../utils/upload-file-transform";
import { videoIdExtractorTransform } from "../../utils/video-id-extractor-transform";

const CreateStepper = ({ steps, currentStep, setStep, ...props }) => {
    return (
        <Stepper activeStep={currentStep}>
            {steps.map((step, idx) => <Step key={`step${idx}`}>
                <StepButton onClick={() => setStep(idx)}>{step.title}</StepButton>
            </Step>)}
        </Stepper>
    )
}

const StepToolbar = ({ steps, currentStep, setStep, ...props }) => {

    const goPrev = () => setStep(currentStep - 1)
    const goNext = () => setStep(currentStep + 1)

    return (
        <Toolbar {...props}>
            <Button label='Anterior' onClick={goPrev} disabled={currentStep === 0} />
            <Button label='Próximo' onClick={goNext} disabled={currentStep === steps.length - 1} />
            <Spacer />
            <SaveButton disabled={currentStep !== steps.length - 1} />
        </Toolbar>
    )
}

const transform = async (data) => {
    let newData = data;
    data = videoIdExtractorTransform(newData)
    data = await uploadFileTransform(newData, 'guideline-types', 'files')
    return { ...newData }
}

const GuidelineTypeCreate = (props) => {
    const steps = [{ id: 0, title: 'Preencher dados gerais' }, { id: 1, title: 'Adicionar vídeos' }, { id: 2, title: 'Anexar arquivos' }]
    const [step, setStep] = useState(0)

    return (
        <>
            <CreateStepper currentStep={step} steps={steps} setStep={setStep} />
            <Create {...props} transform={transform}>
                <SimpleForm
                    toolbar={<StepToolbar steps={steps} currentStep={step} setStep={setStep} />}
                >
                    {step === 0 && (
                        <>
                            <TextInput source="name" label={'Nome'} fullWidth />
                            <BooleanInput source="public" label={'Pública?'} fullWidth />
                            <RichTextInput source='details' label='Orientações' />
                        </>
                    )}
                    {step === 1 && (
                        <>
                            <ArrayInput source="links" label="Vídeos" variant="standard">
                                <SimpleFormIterator>
                                    <TextInput source="title" label="Título" validate={[required()]} fullWidth />
                                    <VideoUrlInput source="url" label="URL" validate={[required()]} fullWidth />
                                </SimpleFormIterator>
                            </ArrayInput>
                        </>
                    )}
                    {step === 2 && (
                        <>
                            <FileInput
                                source="files"
                                multiple={true}
                                labelMultiple={`Selecione arquivos ou arraste-os para esta área para anexá-los.`}
                                maxSize={25000000}
                            >
                                <FileField source="path" title="title" />
                            </FileInput>
                        </>
                    )}
                </SimpleForm>
            </Create >
        </>
    )
}

export default GuidelineTypeCreate;