import React from "react";
import { BooleanInput, Edit, FormDataConsumer, FormTab, required, TabbedForm, TextInput } from "react-admin";
import MaskedInput from "../../components/masked-input";

const ClientEdit = (props) =>
    <Edit
        mutationMode="pessimistic"
        {...props}
    >
        <TabbedForm>
            <FormTab label={'Dados Gerais'}>
                <TextInput source="name" label={'Nome'} fullWidth />
                <FormDataConsumer>
                    {({ formData }) => {
                        return !formData?.no_contact_info && <>
                            <MaskedInput label={'Telefone'} source="phone" onlyNumbers={true} mask={'(99) 99999-9999'} validate={[required()]} fullWidth autoComplete="off" />
                            <TextInput label={'Email'} source="email" fullWidth />
                        </>
                    }}
                </FormDataConsumer>
                <BooleanInput source='no_contact_info' label={'Contato não informado'} fullWidth />
            </FormTab>
        </TabbedForm>
    </Edit>

export default ClientEdit;