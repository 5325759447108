import { Menu } from "@material-ui/icons";
import RichTextInput from "ra-input-rich-text";
import React, { useState } from "react";
import {
    ArrayInput,
    BooleanInput,
    Button,
    CheckboxGroupInput,
    Create,
    FileField,
    FileInput,
    FormDataConsumer,
    FormTab,
    ReferenceInput, SelectInput, SimpleFormIterator,
    TabbedForm, TextInput, Title, required, useNotify, useRedirect, useRefresh
} from "react-admin";
import HideableFormTab from "../../components/hideable-form-tab";
import { VideoUrlInput } from "../../components/video-url-input";
import { uploadFileTransform } from "../../utils/upload-file-transform";
import { videoIdExtractorTransform } from "../../utils/video-id-extractor-transform";

const SchoolModelActivityTaskCreate = (props) => {

    const [showTab, setShowTab] = useState(false)

    const compare = (record) => record.is_ai_assisted || showTab

    const state = props.history.location.state ? props.history.location.state : { data: null, redirectTo: null }

    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();
    const onSuccess = ({ data }) => {
        notify(`Movimentação criada com sucesso`);
        redirect(state.redirectTo !== null ? state.redirectTo : `/model_activities/${data.model_activity.id}/activities`);
        refresh();
    };

    const transform = async (data) => {
        let newData = data;
        data = videoIdExtractorTransform(newData)
        if (data.ai_attachments)
            data = await uploadFileTransform(newData, 'school-model-activity-tasks-ai-guidelines', 'ai_attachments')
        return { ...newData }
    }

    return (
        <Create title={<Title title={'Nova Etapa Matriz'} />} onSuccess={onSuccess} transform={transform}
            actions={<Button style={{ marginBottom: '16px' }} label={'Dashboard'} variant="contained" startIcon={<Menu />} onClick={() => redirect(`/model-activity-dashboard/${state.data.id}`)} />}
            {...props}>
            <TabbedForm warnWhenUnsavedChanges syncWithLocation={false}>
                <FormTab label="Dados Principais">
                    <TextInput source="model_activity.id" initialValue={state.data.id} style={{ display: 'none' }} fullWidth disabled />
                    <TextInput source="model_activity.name" initialValue={state.data.name} label={'Atividade matriz'} fullWidth disabled />
                    <TextInput source="name" label="Título" variant="filled" fullWidth validate={[required()]} />
                    <CheckboxGroupInput
                        source="roles"
                        label="Partes que podem ver esta etapa"
                        variant="filled"
                        choices={state.data.party_types.map(item => ({ ...item, id: item.id.toString() }))}
                        optionText="name"
                        optionValue={'id'}
                        fullWidth
                        parse={value =>
                            value && value.map(v => ({ id: v }))
                        }
                        format={value => value && value.map(v => v.id)}
                        validate={[required()]}
                    />
                    <ReferenceInput source="guideline_type" reference="guideline-types" label="Orientação padrão" fullWidth>
                        <SelectInput source="name" optionText={"name"} fullWidth />
                    </ReferenceInput>

                    <BooleanInput fullWidth source="is_ai_assisted" label="Utiliza a correção por IA do Juristta" defaultValue={false} onChange={(e) => setShowTab(e)} />
                    <BooleanInput fullWidth source="video" label="Audiência de vídeo" />
                    <BooleanInput fullWidth source="metaverse" label="Audiência no metaverso" />
                    <BooleanInput fullWidth source="requires_tpu" label="Requer código TPU" />
                    <BooleanInput fullWidth source="requires_grade" label="Requer correção" defaultValue={true} />
                    <FormDataConsumer>
                        {({ formData, record, ...rest }) => formData.requires_grade && (
                            <SelectInput
                                source="grade_type" label={'Tipo de avaliação'} defaultValue='G'
                                choices={[{ id: 'T', name: 'Carga horária' }, { id: 'G', name: 'Nota' }]} fullWidth
                            />
                        )}
                    </FormDataConsumer>
                    <FormDataConsumer>
                        {
                            ({ formData, record, ...rest }) => {
                                if (formData.requires_grade) {
                                    if (formData.grade_type === 'T') {
                                        return <TextInput source="reference_grade" label={'Carga horária de referência'} step={1} min={0} fullWidth />
                                    } else if (formData.grade_type === 'G') {
                                        return <TextInput source="reference_grade" label={'Nota de referência'} step={1} min={0} fullWidth />
                                    }
                                }
                            }
                        }
                    </FormDataConsumer>

                    <ArrayInput
                        source="checklist"
                        label="Checklist de correção"
                        variant="filled"
                    >
                        <SimpleFormIterator>
                            <TextInput source="description"  validate={[required()]} label="Descrição" fullWidth />
                        </SimpleFormIterator>
                    </ArrayInput>
                </FormTab>
                <FormTab label="Instruções" path="details">
                    <RichTextInput
                        source="details"
                        label="Descrição"
                        validate={[required()]}
                    // toolbar={quillConfig}
                    />
                    <ArrayInput source="links" label="Vídeos" variant="standard">
                        <SimpleFormIterator>
                            <TextInput
                                source="title"
                                label="Título"
                                validate={[required()]}
                                fullWidth
                            />
                            <VideoUrlInput source="url" label="URL" validate={[required()]} fullWidth />
                        </SimpleFormIterator>
                    </ArrayInput>
                </FormTab>
                <HideableFormTab label="Espelho de correção" path="guidelines" comparison={compare}>
                    <RichTextInput source="ai_guidelines" label="Espelho de correção da movimentação" validate={[required()]} />
                </HideableFormTab>
                <HideableFormTab label="Autos do processo" path="document" comparison={compare}>
                    <FileInput
                        label="Arquivos"
                        source="ai_attachments"
                        multiple={true}
                        labelMultiple={`Selecione arquivos ou arraste-os para esta área para anexá-los.`}
                        maxSize={25000000}
                        validate={[required()]}
                    >
                        <FileField source="path" label="Arquivos" title="title" />
                    </FileInput>
                </HideableFormTab>
            </TabbedForm>
        </Create>
    );
}


export default SchoolModelActivityTaskCreate 