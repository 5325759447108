import RichTextInput from 'ra-input-rich-text';
import React from "react";
import { ArrayInput, BooleanInput, Button, Datagrid, DeleteButton, Edit, FormDataConsumer, FormTab, ReferenceManyField, required, SelectInput, SimpleFormIterator, TabbedForm, TextField, TextInput } from "react-admin";
import AddNewAttachmentButton from "../../components/add-new-attachment-button";
import { VideoUrlInput } from '../../components/video-url-input';
import { videoIdExtractorTransform } from '../../utils/video-id-extractor-transform';

const ActivityTypeEdit = (props) => {

    const CustomTitle = ({ record }) => {
        return <span> {`Tipo de movimentação - ${record.name}`} </span>
    }

    const ViewFileButton = (props) => {
        return <Button onClick={() => { window.open(props.record.path) }} label={'Visualizar'} />
    }

    return (
        <Edit
            mutationMode="pessimistic"
            {...props}
            title={<CustomTitle />}
            transform={videoIdExtractorTransform}>
            <TabbedForm>
                <FormTab label={'Dados Gerais'}>
                    <TextInput source="name" label={'Nome'} fullWidth />
                    <RichTextInput source="observations" label={'Orientações'} fullWidth />
                    <BooleanInput source="is_hearing" label={'É audiência?'} />
                    <FormDataConsumer>
                        {
                            ({ formData, record, ...rest }) => { return !formData.is_hearing && <BooleanInput source="requires_videoconference" label={'Requer videoconferência'} /> }
                        }
                    </FormDataConsumer>
                    <SelectInput source="workload_type" label={'Tipo de avaliação'} choices={[{ id: 'C', name: 'Cumprimento de atividade' }, { id: 'T', name: 'Carga horária' }, { id: 'G', name: 'Nota' }]} fullWidth />
                    <FormDataConsumer>
                        {
                            ({ formData, record, ...rest }) => {
                                if (formData.workload_type === 'T') {
                                    return <TextInput source="reference_workload" label={'Carga horária de referência'} step={1} min={0} fullWidth />
                                } else if (formData.workload_type === 'G') {
                                    return <TextInput source="reference_workload" label={'Nota de referência'} step={1} min={0} fullWidth />
                                } else {
                                    return <></>
                                }
                            }
                        }
                    </FormDataConsumer>
                </FormTab>
                <FormTab label={"Vídeos"}>
                    <ArrayInput label="Vídeos" source="links">
                        <SimpleFormIterator>
                            <TextInput source="title" label="Título" validate={[required()]} fullWidth />
                            <VideoUrlInput source="url" label="URL" validate={[required()]} fullWidth />
                        </SimpleFormIterator>
                    </ArrayInput>
                </FormTab>
                <FormTab label={'Anexos'} path="attachments" >
                    <ReferenceManyField fullWidth reference='attachments' target='activityTypeId' label={'Anexos'}>
                        <Datagrid>
                            <TextField source={'name'} label={'Nome'} />
                            <ViewFileButton />
                            <DeleteButton undoable={false} redirect={false} />
                        </Datagrid>
                    </ReferenceManyField>
                    <AddNewAttachmentButton multiple={true} />
                </FormTab>
            </TabbedForm>
        </Edit>
    )
}

export default ActivityTypeEdit;