
import { Typography } from "@material-ui/core"
import { Code, CodeBlock, CopyBlock, dracula } from "react-code-blocks"

export const ConsultaTurma = () => {


    const text = `GET /v1/consulta-turma/9565dda7-d7c0-4846-8333-282449987c5b
Host: api.app.juristta.com
Authorization: Bearer v1_a2f5i10a_abc123def456ghi789jk`

    const text2 = `{
    "id": "9565dda7-d7c0-4846-8333-282449987c5b",
    "active": true,
    "name": "DC I - M",
    "description": "Turma de Direito Civil I período Matutino.",
    "users": [
        {
            "id": "c40aa97c-8f88-afe7-409a-3465045e44b4",
            "name": "João Silva",
            "email": "joao.silva@example.com",
            "phone": "(31) 99999-9999"
        },
        {
            "id": "91d9683c-aadc-4a1f-bac6-0dcecbd40202",
            "nome": "Maria Oliveira",
            "email": "maria.oliveira@example.com",
            "phone": null
        }
    ],
    "academicPeriod":{
        "id": "1234abcd-ef56-gh78-ij90-123456abcdef",
        "name": "02/2024",
        "description": "2º semestre 2024",
        "start_at": "2024-01-01",
        "end_at": "2024-06-30",
    }
}`

    return <>
        <Typography variant="h6">Consulta de turma</Typography>
        <div style={{ marginBottom: '16px' }} />

        <Typography variant='body1'>Este endpoint permite consultar informações detalhadas de uma turma da instituição, como o id associado no sistema, o período letivo que pertence e os alunos matriculados.</Typography>
        <div style={{ marginBottom: '16px' }} />

        <div style={{ marginBlock: '16px', borderRadius: '8px', backgroundColor: '#1f1f1f', padding: '8px' }} >
            <div>
                <Typography variant="body1" component='span' style={{ fontWeight: 'bold' }}>Método:</Typography> <Typography component='span'>GET</Typography>
            </div>
            <div>
                <Typography variant="body1" component='span' style={{ fontWeight: 'bold' }}>URL:</Typography> <Typography component='span'>https://api.app.juristta.com/v1/consulta-turma/{"{"}id{"}"} </Typography>
            </div>
            <div>
                <Typography variant="body1" component='span' style={{ fontWeight: 'bold' }}>Parâmetro: </Typography> <Typography component='span'> {"{"}id{"}"}: Id da turma</Typography>
            </div>

        </div>

        <Typography variant="h6">Exemplo de Requisição </Typography>
        <Typography variant="body1" style={{ fontFamily: 'monospace', marginBlock: '16px' }}>
            <CopyBlock codeBlockStyle={{ fontFamily: 'Arial' }} text={text} language="http" theme={dracula} codeBlock showLineNumbers />
        </Typography>

        <div style={{ marginBottom: '16px' }} />

        <Typography variant="h6">Exemplo de Resposta </Typography>
        <Typography variant="body1" style={{ fontFamily: 'monospace', marginBlock: '16px' }}>
            <CodeBlock codeBlockStyle={{ fontFamily: 'Arial' }} text={text2} language="json" theme={dracula} />
        </Typography>
        <Typography variant="body1">Requisições que não seguirem corretamente a especificação receberão uma resposta com o status <Code language='http' text="HTTP 400 Bad Request." theme={dracula} ></Code> com detalhes do problema. </Typography>
    </>
}