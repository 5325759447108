import { Resource } from "react-admin";
import { academicPeriodResource } from "../../resources/academic-period";
import { activityResource } from "../../resources/activities";
import { activityNoteResource } from "../../resources/activity-notes";
import { activityTypeResource } from "../../resources/activity-types";
import { attachmentResource } from "../../resources/attachments";
import { classResource } from "../../resources/classes";
import { clientResource } from "../../resources/clients";
import { externalServiceRequestResource } from "../../resources/external-service-requests";
import { guidelineTypeResource } from "../../resources/guideline-types";
import { hearingResource } from "../../resources/hearings";
import { institutionResource } from "../../resources/institutions";
import { partyResource } from "../../resources/parties";
import { partyTypeResource } from "../../resources/party-types";
import { myPermissionsResource, permissionsResource, permissionsResourceResource } from "../../resources/permissions";
import { processNoteResource } from "../../resources/process-notes";
import { processTypeResource } from "../../resources/process-types";
import { processResource } from "../../resources/processes";
import { profileResource } from "../../resources/profiles";
import { schoolActivitiesResource } from "../../resources/school-activities";
import { schoolActivityTaskResource } from "../../resources/school-activity-task";
import { schoolModelActivitiesResource } from "../../resources/school-model-activities";
import { schoolModelActivityTaskResource } from "../../resources/school-model-activity-task";
import { serviceRequestResource } from "../../resources/service-requests";
import { themeResource } from "../../resources/themes";
import { userResource } from "../../resources/users";
import { schedulingResource } from "../../resources/scheduling";
import { institutionBranchResource } from "../../resources/institution-branches";

const resources = [
  institutionResource,
  profileResource,
  academicPeriodResource,
  classResource,
  partyTypeResource,
  processTypeResource,
  userResource,
  clientResource,
  partyResource,
  activityTypeResource,
  externalServiceRequestResource,
  serviceRequestResource,
  processResource,
  processNoteResource,
  activityResource,
  activityNoteResource,
  permissionsResourceResource,
  permissionsResource,
  myPermissionsResource,
  attachmentResource,
  hearingResource,
  schoolActivitiesResource,
  schoolActivityTaskResource,
  schoolModelActivitiesResource,
  schoolModelActivityTaskResource,
  guidelineTypeResource,
  themeResource,
  schedulingResource,
  institutionBranchResource,
]

export const resourceProvider = {

  getAllResources: () => {
    return resources;
  },

  getResource: (name) => {
    return resources.find(resource => resource.name === name)
  },

  getResourcesByProduct: (product) => {
    return resources.filter(resource => resource.products.find(el => el === product))
  },

  getEnabledResources: (appContext) => {

    if (appContext.currentUser === null) return []

    const enabledResources = [];

    resources.forEach(resource => {
      if (resource.isPublic || appContext.superAdmin) {
        enabledResources.push(
          <Resource
            key={resource.name}
            {...resource}
          />
        )
        return;
      }
      const permission = appContext.permissions?.find(w => w.resourceName === resource.name);
      const parsedResource = { ...resource };
      if (!permission) return;
      if (!permission.read) {
        parsedResource.list = undefined;
        parsedResource.show = undefined;
      }
      if (!permission.write) {
        parsedResource.create = undefined;
        parsedResource.edit = undefined;
      }
      enabledResources.push(
        <Resource
          key={resource.name}
          {...parsedResource}
        />
      )
    })
    return enabledResources;
  }

}