import { Typography } from '@material-ui/core'
import 'moment/locale/pt-br'
import React from 'react'
import ExpandableCard from '../../components/expandable-card'

export const ProcessClient = ({ process, ...props }) => {

    return (
        <ExpandableCard realce={true} title={`Cliente`}>
            <Typography color='Secondary'>Nome:     <Typography color='textSecondary' component={"span"}> {process.client.name} </Typography></Typography>

            {
                process.client.no_contact_info ?
                    <>
                        <Typography color='textSecondary'>Contato não informado</Typography>
                    </>
                    : <>
                        <Typography color='Secondary'>Email:    <Typography color='textSecondary' component={"span"}> {process.client.email} </Typography></Typography>
                        <Typography color='Secondary'>Telefone: <Typography color='textSecondary' component={"span"}> {process.client.phone} </Typography></Typography>
                    </>
            }

        </ExpandableCard>
    )
}