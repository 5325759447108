import { BooleanField, Datagrid, List, TextField } from "react-admin"

const InstitutionBranchList = (props) => {
    return (
        <List {...props}>
            <Datagrid rowClick={'edit'}>
                <TextField source="institution.name" label={'Insituição'} />
                <TextField source="name" label={'Unidade'} />
                <BooleanField source="active" label={'Ativo'} />
            </Datagrid>
        </List>
    )
}

export default InstitutionBranchList;