import {
    Button,
    CardContent, CardMedia, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
    Divider,
    Grid,
    IconButton,
    Link,
    List,
    ListSubheader,
    makeStyles, Tooltip, Typography
} from '@material-ui/core'
import { AttachFile, CalendarToday, Check, Edit, Explore, MoreHoriz, Schedule, VideoCall, VoiceChat, Warning } from '@material-ui/icons'
import {
    TimelineConnector, TimelineContent, TimelineDot,
    TimelineItem, TimelineOppositeContent, TimelineSeparator
} from '@material-ui/lab'
import Vimeo from '@u-wave/react-vimeo'
import YouTube from '@u-wave/react-youtube'
import moment from 'moment'
import 'moment/dist/locale/pt-br'
import React, { useContext, useState } from 'react'
import { useRedirect } from 'react-admin'
import Carousel from 'react-material-ui-carousel'
import { DangerousHTMLDiv } from '../../components/dangerousHTMLDiv'
import ExpandableCard from '../../components/expandable-card'
import { ListAttachments } from '../../components/list-attachments/list-attachments.component'
import { Spacer } from '../../components/spacer'
import { AppContext } from '../../providers/app-context-provider/app.context'
import { SchoolActivityDateSelector } from './school-activity-date-selector'
import { TaskCompletion } from './task-completion.component'
moment.locale('pt-br');

const useStyles = makeStyles((theme) => ({
    notStarted: {
        backgroundColor: '#a5a5a5',
    },
    inProgress: {
        backgroundColor: '#1b63cf',
    },
    completedSuccess: {
        backgroundColor: '#44bb33',
    },
    completedAlert: {
        backgroundColor: '#ebdc38',
    },
    completedFailure: {
        backgroundColor: '#bb3333',
    }
}))

const EditTaskButton = ({ task, ...props }) => {
    const redirect = useRedirect();
    const handleClick = () => {
        redirect(`/school-activity-tasks/${task.id}`)
    }
    return <Tooltip title={'Editar a movimentação'}><IconButton color={'text'} onClick={handleClick}><Edit /></IconButton></Tooltip>
}


export const SchoolActivityTaskPanel = ({ task, isStudent, school_activity, team, setSchoolActivity, refresh, toggleRefresh, ...props }) => {
    const classes = useStyles()
    const redirect = useRedirect();

    const [guidelineOpen, setGuidelineOpen] = useState(false)
    const [attachments, setAttachments] = useState(task.attachments)
    const [metaverseDialogOpen, setMetaverseDialogOpen] = useState(false)
    const [open, setOpen] = useState(false)

    const handleGuidelineClose = () => setGuidelineOpen(false)
    const handleGuidelineOpen = () => setGuidelineOpen(true)

    const context = useContext(AppContext)

    const content = <DangerousHTMLDiv html={task.details} />

    const userRole = team?.team_memberships?.find(tm => tm?.student?.id === context?.currentUser?.id).role
    const shouldShow = isStudent ? (task?.roles?.map(role => role.id).includes(userRole?.id) && moment().isAfter(moment(task.start_at))) : true
    const urlFix = (url) => url ? (url.startsWith('http://') || url.startsWith('https://')) ? url : 'https://' + url : ''

    const formattedDeadline = moment(task.student_end_at).set('hour', 23).set('minutes', 59)
    const formattedEffectiveDeadline = moment(task.teacher_end_at).set('hour', 23).set('minutes', 59)
    let status;

    if (task.task_completions?.length > 0) {
        status = 'completedSuccess'
    } else {
        if (moment().isBefore(moment(task.start_at))) {
            status = 'notStarted'
        } else if (moment().isBefore(moment(formattedDeadline))) {
            status = 'inProgress'
        } else if (moment().isBetween(moment(formattedDeadline), moment(formattedEffectiveDeadline))) {
            status = isStudent ? 'completedFailure' : 'completedAlert'
        } else if (moment().isAfter(formattedEffectiveDeadline)) {
            status = 'completedFailure'
        }
    }


    return (
        <TimelineItem>
            <TimelineOppositeContent style={{ display: 'none' }} />
            <TimelineSeparator>
                <TimelineDot className={classes[status]} >
                    {
                        status === 'notStarted' ? <Schedule /> :
                            status === 'inProgress' ? <MoreHoriz /> :
                                status === 'completedSuccess' ? <Check /> :
                                    status === 'completedAlert' ? <Warning /> :
                                        status === 'completedFailure' ? <Warning /> : <MoreHoriz />
                    }
                </TimelineDot>
                <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
                <ExpandableCard
                    realce
                    title={task.name}
                    content={shouldShow && content}
                    actionTextShow='Instruções'
                    actionTextHide='Ocultar'
                    action={shouldShow && <>
                        {!isStudent && <EditTaskButton task={task} />}
                        {!isStudent && <Tooltip title={'Anexar'}>
                            <IconButton
                                onClick={
                                    () => {
                                        redirect('create', '/attachments', {}, {}, { redirectTo: `/school-activity-dashboard/${school_activity.id}`, resource: 'school-activity-tasks', id: task.id, fileSize: 25000000 })
                                    }}>
                                <AttachFile />
                            </IconButton>
                        </Tooltip>}
                        {task.guideline_type && <IconButton onClick={() => handleGuidelineOpen()}><Explore /></IconButton>}
                    </>}>
                    <CardContent>
                        <Grid container alignItems='center'>

                            <Typography component={'div'} style={{ width: '100%', marginBottom: '8px' }}>
                            {`${task.grade_type === 'G' ? 'Nota' : 'Carga horária'} de referência:`}
                            <Typography color='textSecondary' component={'span'} style={{ marginInline: '4px' }}>
                                    {task.reference_grade ? (task.reference_grade + ' ' + (task.grade_type === 'G' ? 'pontos' : 'horas')) : 'A definir'}
                                </Typography>
                            </Typography>


                            <Typography component={'div'} style={{ marginBottom: '8px' }}>
                                {task.roles?.length > 1 ? 'Sujeito processual relacionado:' : 'Sujeitos processuais relacionados:'}

                                {task.roles.length > 0 ?
                                    task.roles.map(role => <Chip style={{ marginInline: '4px' }} size='small' label={role.name} />)
                                    : <Typography color='textSecondary' component={'span'}>A definir</Typography>}
                            </Typography>
                        </Grid>

                        {shouldShow && task.video && task.video_url && <Grid container alignItems='center'>
                            <Typography style={{ fontWeight: 'bold', flexBasis: '25%' }}>
                                Audiência de vídeo
                            </Typography>
                            <Typography>
                                {task.video_date ? moment(task.video_date).format('DD/MM/yyyy [às] HH:mm') : 'A definir'}
                            </Typography>
                            <Spacer />
                            {task.video_date && <Button endIcon={<VoiceChat />} href={urlFix(task.video_url)} disabled={moment().isBefore(task.video_date)} target='_blank'>
                                {moment().isBefore(task.video_date) ? `Disponível ${moment().to(task.video_date)}` : 'Participar'}
                            </Button>}
                        </Grid>
                        }


                        {shouldShow && task.metaverse && context.institution.video_room && <Grid container alignItems='center'>
                            <Typography style={{ fontWeight: 'bold', flexBasis: '25%' }}>
                                Audiência no metaverso
                            </Typography>
                            <Typography>
                                {task.metaverse_video_date ? moment(task.metaverse_video_date).format('DD/MM/yyyy [às] HH:mm') : 'A definir'}
                            </Typography>
                            <Spacer />
                            {task.metaverse_video_date && <Button endIcon={<VideoCall />} disabled={moment().isBefore(task.metaverse_video_date)} onClick={() => setMetaverseDialogOpen(true)}>
                                {moment().isBefore(task.metaverse_video_date) ? `Disponível ${moment().to(task.metaverse_video_date)}` : 'Participar'}
                            </Button>}
                            <Dialog open={metaverseDialogOpen} onClose={() => setMetaverseDialogOpen(false)}>
                                <DialogTitle>Redirecionando ao metaverso</DialogTitle>
                                <DialogContent dividers>
                                    <DialogContentText>
                                        Para utilizar a sala do metaverso de sua instituição, certifique-se que você possui e está logado em uma conta do spacial.io
                                    </DialogContentText>
                                    <DialogContentText>
                                        Para criar ou fazer login em sua conta no spacial.io, acesse <Link href='https://www.spatial.io/?login=true' target='_blank'>este link</Link>.
                                    </DialogContentText>
                                    <DialogContentText>
                                        Clique no botão 'Participar' abaixo para ser direcionado para a sala do metaverso de sua instituição!
                                    </DialogContentText>
                                    <YouTube video='-NB-Gk2PAZU' width={'100%'} height={'310px'} showRelatedVideos={false} showCaptions={false} modestBranding={true} />
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={() => setMetaverseDialogOpen(false)} variant='contained'>Cancelar</Button>
                                    <Spacer />
                                    <Button endIcon={<VideoCall />} href={urlFix(context.institution.video_room)} target='_blank' color='primary' variant='contained'>Participar</Button>
                                </DialogActions>
                            </Dialog>
                        </Grid>
                        }

                        <Divider style={{ marginBlock: '16px' }} />

                        {
                            (isStudent || (task.start_at && task.student_end_at && task.teacher_end_at)) ?
                                <>
                                    <Grid container>
                                        <Grid container item style={{ flexBasis: '55%' }}>
                                            <Grid item>
                                                <Typography>
                                                    <CalendarToday fontSize='12' /> Data de início:
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid container item style={{ flexBasis: '45%' }}>
                                            <Grid item>
                                                {
                                                    task.start_at ?
                                                        <Typography color='textSecondary' component={'span'}>{moment(task.start_at, 'YYYY-MM-DD').format('LL')}</Typography> :
                                                        <Typography color='textSecondary' component={'span'}>A definir</Typography>
                                                }
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid container item style={{ flexBasis: '55%' }}>
                                            <Grid item>
                                                <Typography style={{ color: isStudent ? '#bb3333' : '#ebdc38' }}>
                                                    <CalendarToday fontSize='12' /> Data de término:
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid container item style={{ flexBasis: '45%' }}>
                                            <Grid item>
                                                {
                                                    task.student_end_at ?
                                                        <Typography color='textSecondary' component={'span'}>{moment(task.student_end_at, 'YYYY-MM-DD').format('LL')}</Typography> :
                                                        <Typography color='textSecondary' component={'span'}>A definir</Typography>
                                                }
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    {!isStudent && <Grid container>
                                        <Grid container item style={{ flexBasis: '55%' }}>
                                            <Grid item>
                                                <Typography style={{ color: '#bb3333' }}>
                                                    <CalendarToday fontSize='12' /> Data limite de correção:
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid container item style={{ flexBasis: '45%' }}>
                                            <Grid item>
                                                {
                                                    task.teacher_end_at ?
                                                        <Typography color='textSecondary' component={'span'}>{moment(task.teacher_end_at, 'YYYY-MM-DD').format("LL")}</Typography> :
                                                        <Typography color='textSecondary' component={'span'}>A definir</Typography>
                                                }
                                            </Grid>
                                        </Grid>
                                    </Grid>}
                                    <Divider style={{ marginBlock: '16px' }} />
                                </>
                                :
                                <>
                                    <Button
                                        variant='outlined'
                                        style={{ width: '100%', height: '50px', marginTop: '8px' }}
                                        //onClick={() => redirect(`/school-activity-tasks/${task.id}/dates`, undefined, task.id, undefined, { tab: 'dates', redirectTo: `/school-activity-dashboard/${school_activity.id}` })}
                                        onClick={setOpen}
                                        startIcon={<CalendarToday />}
                                    >
                                        Definir Prazos
                                    </Button>
                                    <SchoolActivityDateSelector schoolActivity={school_activity} setSchoolActivity={setSchoolActivity} refresh={refresh}
                                        toggleRefresh={toggleRefresh} open={open} setDialogOpen={setOpen} tasks={school_activity.tasks} />
                                    <Divider style={{ marginBlock: '16px' }} />
                                </>

                        }

                        {
                            isStudent && shouldShow && task.requires_grade && (
                                <>
                                    <TaskCompletion team={team} task={task} role={userRole} />
                                    <Divider style={{ marginBlock: '16px' }} />
                                </>
                            )
                        }
                    </CardContent>
                    <CardMedia>
                        {shouldShow && task.links && <Carousel autoPlay={false} navButtonsAlwaysVisible={task.links?.length > 1}>
                            {
                                task.links.map((video, index) => {
                                    if (video.source === 'youtube') return <YouTube showCaptions={false} annotations={false} modestBranding={true} showRelatedVideos={false} autoplay={false} video={video.id} id={index} height={'457px'} width={'100%'} />
                                    if (video.source === 'vimeo') return <Vimeo video={video.id} id={index} responsive={true} />
                                    else return null;
                                })
                            }
                        </Carousel>}
                    </CardMedia>

                    {
                        shouldShow && attachments.length > 0 &&
                        <List dense subheader={<ListSubheader disableSticky>Anexos</ListSubheader>}>
                            <ListAttachments attachments={attachments} setAttachments={setAttachments} enableDelete={!isStudent} relatedResource={'school-activity-tasks'} relatedId={task.id} />
                        </List>
                    }
                </ExpandableCard>
                <Dialog open={guidelineOpen} onClose={handleGuidelineClose} scroll={"paper"} fullWidth maxWidth={'sm'}>
                    <DialogTitle>
                        {`Orientação - ${task.guideline_type?.name}`}
                    </DialogTitle>
                    <DialogContent dividers={true}>
                        <DialogContentText>
                            <DangerousHTMLDiv html={task.guideline_type?.details} />
                        </DialogContentText>
                        <Carousel autoPlay={false} navButtonsAlwaysVisible={task.guideline_type?.links?.length > 1}>
                            {
                                task.guideline_type?.links?.map((video, index) => {
                                    if (video.source === 'youtube') return <YouTube autoplay={false} showCaptions={false} showRelatedVideos={false} modestBranding={true} video={video.id} id={index} height={'457px'} width={'100%'} />
                                    if (video.source === 'vimeo') return <Vimeo video={video.id} id={index} responsive />
                                    else return null;
                                })
                            }
                        </Carousel>
                        <List dense subheader={<ListSubheader disableSticky>Anexos</ListSubheader>}>
                            <ListAttachments enableDelete={false} attachments={task?.guideline_type?.attachments} />
                        </List>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleGuidelineClose}>
                            Fechar
                        </Button>
                    </DialogActions>
                </Dialog>
            </TimelineContent>
        </TimelineItem >
    )
}
