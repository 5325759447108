export default class ApplicationResource {
  static ACADEMIC_PERIODS = 'academic-periods';
  static ACTIVITIES = 'activities';
  static ACTIVITY_NOTES = 'activity-notes';
  static ACTIVITY_TYPES = 'activity-types';
  static ATTACHMENTS = 'attachments';
  static CLASSES = 'classes';
  static CLIENTS = 'clients';
  static EXTERNAL_SERVICE_REQUESTS = 'external-service-requests';
  static GUIDELINE_TYPE = 'guideline-types';
  static HEARINGS = 'hearings';
  static INSTITUTIONS = 'institutions';
  static PARTIES = 'parties';
  static PARTY_TYPE = 'party-types';
  static PERMISSIONS = 'permissions';
  static PROFILES = 'profiles';
  static PROCESSES = 'processes';
  static PROCESS_NOTES = 'process-notes';
  static PROCESS_TYPES = 'process-types';
  static SCHEDULING = 'schedulings'
  static SCHOOL_ACTIVITIES = 'school-activities';
  static SCHOOL_ACTIVITY_TASKS = 'school-activity-tasks';
  static SCHOOL_MODEL_ACTIVITIES = 'school-model-activities';
  static SCHOOL_MODEL_ACTIVITY_TASKS = 'school-model-activity-tasks';
  static SERVICE_REQUESTS = 'service-requests';
  static THEMES = 'themes';
  static TRIAGES = 'triages';
  static USERS = 'users';
  static INSTITUTION_BRANCHES = 'institution-branches';
}