import { CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from "@material-ui/core"
import { Cancel, Close, Save } from "@material-ui/icons"
import { Button, FileField, FileInput, FormWithRedirect, required, SaveButton, useNotify } from "react-admin"
import pdfToText from "react-pdftotext"
import MarkdownView from "react-showdown"
import dataProvider from "../../providers/data-provider"
import { useState } from "react"


export const AIEvaluationTest = ({ record, model, ...props }) => {

    const notify = useNotify();

    const [loading, setLoading] = useState(false)
    const [open, setOpen] = useState(false)
    const [AIResponse, setAIResponse] = useState(null)

    const handleOpen = () => setOpen(true)
    const handleClose = () => { if (!loading) { setOpen(false); setAIResponse(null) } };

    const handleSubmit = (values) => {
        pdfToText(values.files.rawFile)
            .then((pdfText) => {
                setLoading(true)
                dataProvider.post('openai', 'evaluationTest', {
                    theme: model ? values.model_activity.themes[0].name : values.school_activity.themes[0].name,
                    teacherDocument: values.ai_guidelines,
                    userDocument: pdfText,
                    max_grade: values.grade_type === 'G' ? values.reference_grade : null,
                }).then(
                    (response) => {
                        setLoading(false)
                        setAIResponse(response.data)
                    }
                ).catch((error) => { setLoading(false); console.error(error) })
            }).catch((error) => {
                notify("Erro ao fazer a leitura do arquivo de teste de correção.", 'error')
                setLoading(false); console.error(error)
            })
    }


    return <>
        <Button variant='contained' onClick={handleOpen} label={'Testar correção'} disabled={!record.ai_guidelines} />
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
            <DialogTitle>Testar correção por IA</DialogTitle>
            <FormWithRedirect
                resource="user"
                save={handleSubmit}
                render={({
                    handleSubmitWithRedirect,
                    pristine,
                    saving
                }) => (
                    <>
                        <DialogContent>
                            {loading ? <DialogContentText> Operação em andamento, aguarde...</DialogContentText>
                                : AIResponse ?
                                    <>
                                        <Typography> Nota: {AIResponse.grade} </Typography>
                                        <Typography> <MarkdownView flavor="github" markdown={AIResponse.feedback} /></Typography>
                                    </>
                                    : <>
                                        <DialogContentText>
                                            Para testar a correção assistida por IA do Juristta, favor providenciar um documento equivalente ao de um aluno solucionando esta movimentação.
                                        </DialogContentText>

                                        <FileInput source="files" label="Arquivo" accept=".pdf" validate={[required()]}>
                                            <FileField source="src" title="title" />
                                        </FileInput>
                                    </>
                            }
                        </DialogContent>
                        <DialogActions>
                            {
                                AIResponse ? <>
                                    <Button
                                        variant='contained'
                                        label="Fechar"
                                        onClick={handleClose}
                                        disabled={loading}
                                    >
                                        <Close />
                                    </Button>
                                </> :
                                    <>
                                        <Button
                                            label="ra.action.cancel"
                                            onClick={handleClose}
                                            disabled={loading}
                                        >
                                            {loading ? <CircularProgress size={16} /> : <Cancel />}
                                        </Button>
                                        <SaveButton
                                            handleSubmitWithRedirect={
                                                handleSubmitWithRedirect
                                            }
                                            pristine={pristine}
                                            saving={saving}
                                            disabled={loading}
                                            icon={loading ? <CircularProgress size={16} /> : <Save />}
                                        />
                                    </>
                            }

                        </DialogActions>
                    </>
                )}
            />
        </Dialog>
    </>

}