import { Delete, Visibility } from "@material-ui/icons";
import RichTextInput from 'ra-input-rich-text';
import React from "react";
import {
    AutocompleteInput,
    BooleanInput,
    Button, Datagrid,
    DateInput,
    DateTimeInput,
    Edit, EmailField,
    FormDataConsumer,
    FormTab, Loading, ReferenceField,
    ReferenceInput,
    ReferenceManyField, SelectInput, TabbedForm,
    TextField, TopToolbar, required, useNotify, useRedirect,
    useRefresh
} from "react-admin";
import AddNewAttachmentButton from "../../components/add-new-attachment-button";
import ViewOrientationsDialog from "../../pages/process-dashboard/view-orientations-dialog";
import dataProvider from "../../providers/data-provider";
import { storageProvider } from "../../providers/storage-provider";
import ActivityEditAddUser from "./activity-edit-add-user";
import AddNewActivityNoteButton from "./add-new-activity-note-button";

import { Button as MuiButton } from '@material-ui/core';
import moment from 'moment';
import AttachmentDeleteButton from "../../components/attachment-delete-button";
import { TruncatedTextField } from "../../components/truncated-text-field";
import cookiesProvider from '../../providers/cookies-provider';
import EvaluateButton from "./evaluate-button";


const ActivityEdit = (props) => {

    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();

    const profiles = storageProvider.getCurrentUser()?.profiles
    const user = cookiesProvider.getUser();

    const isTeacherOrAdmin = profiles.find(
        p => p.name === 'Administrador'
            || p.name === 'Professor'
            || p.name === 'Advogado'
            || p.name === 'Coordenador'
    )
    const onSuccess = ({ data }) => {
        notify(`Item atualizado com sucesso`);
        redirect(`/processes/${data.process.id}/activities`);
        refresh();
    };

    const handleRemoveUser = (processId, userId) => {
        dataProvider.delete(`activities/${processId}/user`, { id: userId })
            .then(() => { refresh(); setTimeout(() => { }, 2000) })
    }

    const ViewFileButton = (props) => {
        return <Button onClick={() => { window.open(props.record.path) }} label={'Visualizar'} />
    }

    const ToolbarEditWithView = ({ data }) => {
        const record = data
        return record === undefined ? <Loading /> : (
            <TopToolbar style={{ justifyContent: "flex-start" }}>
                <Button onClick={() => { redirect(`/process-dashboard/${record.process.id}`) }} label={'Dashboard'}>
                    <Visibility />
                </Button>
                <EvaluateButton record={record} userId={user} />

                <div style={{ flexGrow: 1 }}></div>
                <ViewOrientationsDialog small={false} activity={record} />
            </TopToolbar>
        )
    }

    const CustomTitle = ({ record }) => {
        return <span> {`Movimentação - ${record?.type?.name}`} </span>
    }

    return (
        <Edit
            onSuccess={onSuccess}
            mutationMode="pessimistic"
            actions={<ToolbarEditWithView />}
            title={<CustomTitle />}
            {...props}
        >
            <TabbedForm>
                <FormTab label={"Dados Gerais"}>
                    <ReferenceInput source="type.id" reference="activity-types" sort={{ field: 'name', order: 'ASC' }} label={'Tipo de movimentação'} validate={[required()]} fullWidth>
                        {/*<SelectInput source="name" fullWidth /> */}
                        <AutocompleteInput source='name' optionText={'name'} />
                    </ReferenceInput>
                    {/*<ReferenceInput source="type.id" label={'Tipo de movimentação'} reference="activity-types" fullWidth >
                        <SelectInput source="name" optionText={"name"} optionValue={"id"} fullWidth />
    </ReferenceInput> */}
                    <FormDataConsumer>
                        {
                            ({ formData, record, ...rest }) => <>
                                {formData?.type?.is_hearing && <SelectInput
                                    source="hearing_type"
                                    label={'Tipo de audiência'}
                                    choices={[{ id: 'p', name: 'Presencial' }, { id: 'v', name: 'Virtual' }]}
                                    fullWidth />}
                                {record?.type?.is_hearing && <DateTimeInput source="conference_date" label={'Data da audiência'} fullWidth />}
                                {record?.type?.requires_videoconference && <DateTimeInput source="conference_date" label={'Data da videoconferência'} fullWidth />}
                            </>
                        }
                    </FormDataConsumer>
                    <DateInput source="start_at" label={'Data de início'} fullWidth />
                    <DateInput source="deadline" label={'Data de término para o aluno'} fullWidth />
                    <DateInput source="effective_deadline" label={'Data de término para os gestores'} fullWidth />
                    <BooleanInput source="finished" label={'Movimentação concluída?'} />
                    <FormDataConsumer>
                        {
                            ({ formData, record, ...rest }) => <>
                                <BooleanInput source="requires_grade" label={formData?.requires_grade ? 'Requer correção' : 'Não requer correção'} />
                            </>
                        }
                    </FormDataConsumer>
                    <RichTextInput source="details" label={'Detalhes'} fullWidth />
                </FormTab>
                <FormTab label={'Alunos'} path="students" >
                    <ReferenceManyField fullWidth reference="users" target="activity.id" label={'Alunos'}>
                        <Datagrid>
                            <TextField source='name' label={'Nome'} fullWidth />
                            <EmailField source='email' label={'Email'} fullWidth />
                            <FormDataConsumer>
                                {
                                    ({ formData, record }) =>
                                        !(formData.workload || formData.teacher_observations) && <Button
                                            label="Remover"
                                            onClick={() => handleRemoveUser(formData.id, record.id)}
                                            startIcon={<Delete />} />
                                }
                            </FormDataConsumer>
                        </Datagrid>
                    </ReferenceManyField>
                    <FormDataConsumer>
                        {
                            ({ formData, record }) =>
                                !(formData.workload || formData.teacher_observations) && (
                                    <ActivityEditAddUser
                                        record={record}
                                        resource={'activities'}
                                        path={'user'}
                                        title={`Adicionar alunos à movimentação ${record?.type?.name}`}
                                        refresh={refresh}
                                    />
                                )
                        }
                    </FormDataConsumer>
                </FormTab>
                <FormTab label={'Notas de movimentação'} path="notes">
                    <ReferenceManyField fullWidth reference="activity-notes" target="activity.id" label={'Notas de movimentação'}>
                        <Datagrid>
                            <ReferenceField source="author.id" reference="users" label={"Autor"}>
                                <TextField source="name" />
                            </ReferenceField>
                            <TruncatedTextField source={"text"} label={'Conteúdo'} />
                        </Datagrid>
                    </ReferenceManyField>
                    <FormDataConsumer>
                        {({ formData, record, ...rest }) => {
                            const editable = isTeacherOrAdmin ? moment().isSameOrBefore(moment(record.effective_deadline), 'day') : moment().isSameOrBefore(moment(record.deadline), 'day')
                            return (
                                <>
                                    <AddNewActivityNoteButton origin={'activities'} record={record} disabled={!editable} />
                                    <span style={{ marginLeft: '5px' }} />
                                    <ActivityNoteButton origin={`/activities/${record.id}/notes`} record={record} />
                                </>
                            )
                        }}
                    </FormDataConsumer>

                </FormTab>

                <FormTab label={'Anexos'} path="attachments">
                    <ReferenceManyField fullWidth reference='attachments' target='activityId' label={'Anexos'}>
                        <Datagrid>
                            <TextField source={'name'} label={'Nome'} />
                            <ViewFileButton />
                            <FormDataConsumer>
                                {({ formData, record, ...rest }) =>
                                    <AttachmentDeleteButton path='activities' resource={'attachments'} relatedId={formData.id} record={record} undoable={false} redirect={false} />
                                }
                            </FormDataConsumer>
                        </Datagrid>
                    </ReferenceManyField>
                    <FormDataConsumer>
                        {({ formData, record, ...props }) => {
                            const editable = isTeacherOrAdmin ? moment().isSameOrBefore(moment(record.effective_deadline), 'day') : moment().isSameOrBefore(moment(record.deadline), 'day')
                            return (<AddNewAttachmentButton record={record} disabled={!editable} multiple={true} resource={'activities'} />)
                        }}
                    </FormDataConsumer>
                </FormTab>
            </TabbedForm>
        </Edit >
    )
}

const ActivityNoteButton = ({ origin, record, ...props }) => {
    const redirect = useRedirect()
    const handleRedirect = () => redirect(`/student-activity-notes/${record.id}`, undefined, undefined, undefined, { origin: origin })
    return <MuiButton onClick={handleRedirect} color='primary' variant="contained" size='small' > Visualizar todas</MuiButton >
}

export default ActivityEdit;