import {
    Button,
    CardContent, CardMedia, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton,
    List,
    ListSubheader,
    Tooltip, Typography
} from '@material-ui/core'
import { AttachFile, Edit, Explore, MoreHoriz } from '@material-ui/icons'
import {
    TimelineConnector, TimelineContent, TimelineDot,
    TimelineItem, TimelineOppositeContent, TimelineSeparator
} from '@material-ui/lab'
import 'moment/locale/pt-br'
import React, { useState } from 'react'
import { useRedirect } from 'react-admin'
import Carousel from 'react-material-ui-carousel'
import ExpandableCard from '../../components/expandable-card'

import Vimeo from '@u-wave/react-vimeo'
import YouTube from '@u-wave/react-youtube'
import { DangerousHTMLDiv } from '../../components/dangerousHTMLDiv'
import { ListAttachments } from '../../components/list-attachments/list-attachments.component'

const EditTaskButton = ({ task, ...props }) => {
    const redirect = useRedirect();
    const handleClick = () => {
        redirect(`/school-model-activity-tasks/${task.id}`)
    }
    return <Tooltip title={'Editar a movimentação'}><IconButton color={'text'} onClick={handleClick}><Edit /></IconButton></Tooltip>
}


export const ModelActivityTaskPanel = ({ task, isStudent, model_activity, ...props }) => {
    const redirect = useRedirect();

    const [guidelineOpen, setGuidelineOpen] = useState(false)
    const [attachments, setAttachments] = useState(task.attachments)

    const handleGuidelineClose = () => setGuidelineOpen(false)
    const handleGuidelineOpen = () => setGuidelineOpen(true)

    const content = <DangerousHTMLDiv html={task.details} />

    return (
        <TimelineItem>
            <TimelineOppositeContent style={{ display: 'none' }} />
            <TimelineSeparator>
                <TimelineDot>
                    <MoreHoriz />
                </TimelineDot>
                <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
                <ExpandableCard
                    realce
                    title={task.name}
                    content={content}
                    actionTextShow='Instruções'
                    actionTextHide='Ocultar'
                    action={<>
                        <EditTaskButton task={task} />
                        <Tooltip title={'Anexar'}>
                            <IconButton
                                onClick={
                                    () => {
                                        redirect('create', '/attachments', {}, {}, { redirectTo: `/model-activity-dashboard/${model_activity.id}`, resource: 'school-model-activity-tasks', id: task.id, fileSize: 25000000 })
                                    }}>
                                <AttachFile />
                            </IconButton>
                        </Tooltip>
                        {task.guideline_type && <IconButton onClick={() => handleGuidelineOpen()}><Explore /></IconButton>}
                    </>}>
                    <CardContent>
                        <Grid container direction='row' alignItems='center'>
                            <Typography component={'div'} style={{ width: '100%', marginBottom: '8px' }}>
                                {`${task.grade_type === 'G' ? 'Nota' : 'Carga horária'} de referência:`}
                                <Typography color='textSecondary' component={'span'} style={{ marginInline: '4px' }}>{task.reference_grade + ' ' + (task.grade_type === 'G' ? 'pontos' : 'horas') }</Typography>
                            </Typography>

                            <Typography component={'div'} style={{ marginBottom: '8px' }}>
                                {task.roles?.length > 1 ? 'Sujeitos processuais relacionados:' : 'Sujeito processual relacionado:'}
                                {task.roles.length > 0 ?
                                    task.roles.map(role => <Chip style={{ marginInline: '4px' }} size='small' label={role.name} />)
                                    : <Typography color='textSecondary' component={'span'}>A definir</Typography>}
                            </Typography>
                        </Grid>
                    </CardContent>
                    <CardMedia>
                        {task.links && <Carousel autoPlay={false} navButtonsAlwaysVisible={task.links?.length > 1}>
                            {
                                task.links.map((video, index) => {
                                    if (video.source === 'youtube') return <YouTube modestBranding={true} showCaptions={false} showRelatedVideos={false} annotations={false} autoplay={false} video={video.id} id={index} height={'457px'} width={'100%'} />
                                    if (video.source === 'vimeo') return <Vimeo video={video.id} id={index} responsive />
                                    else return null;
                                })
                            }
                        </Carousel>}
                    </CardMedia>

                    {
                        attachments.length > 0 &&
                        <List dense subheader={<ListSubheader disableSticky>Anexos</ListSubheader>}>
                            <ListAttachments attachments={attachments} setAttachments={setAttachments} enableDelete={true} relatedResource={'school-model-activity-tasks'} relatedId={task.id} />
                        </List>
                    }
                </ExpandableCard>
                <Dialog open={guidelineOpen} onClose={handleGuidelineClose} scroll={"paper"} fullWidth maxWidth={'sm'}>
                    <DialogTitle>
                        {`Orientação - ${task.guideline_type?.name}`}
                    </DialogTitle>
                    <DialogContent dividers={true}>
                        <DialogContentText>
                            <DangerousHTMLDiv html={task.guideline_type?.details} />
                        </DialogContentText>
                        <CardMedia style={{ justifyContent: 'center' }}>
                            {task.guideline_type?.links &&
                                <Carousel autoPlay={false} navButtonsAlwaysVisible={task.guideline_type?.links?.length > 1}>
                                    {
                                        task.guideline_type.links.map((video, index) => {
                                            if (video.source === 'youtube') return <YouTube modestBranding={true} showCaptions={false} showRelatedVideos={false} annotations={false} autoplay={false} video={video.id} id={index} height={'457px'} width={'100%'} />
                                            if (video.source === 'vimeo') return <Vimeo video={video.id} id={index} responsive />
                                            else return null;
                                        })
                                    }
                                </Carousel>}
                        </CardMedia>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleGuidelineClose}>
                            Fechar
                        </Button>
                    </DialogActions>
                </Dialog>
            </TimelineContent>
        </TimelineItem >
    )
}