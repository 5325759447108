import { Avatar, Button as MuiButton, Dialog, DialogContent, DialogTitle, List, ListItem, ListItemAvatar, ListItemText, ListSubheader } from "@material-ui/core";
import { Delete, Person, RateReview } from "@material-ui/icons";
import axios from "axios";
import RichTextInput from "ra-input-rich-text";
import React, { useState } from "react";
import { BooleanInput, Button, required, SaveButton, SelectInput, SimpleForm, TextInput, Toolbar, useNotify, useRefresh } from "react-admin";
import cookiesProvider from "../../providers/cookies-provider";
import { getApiUrl } from "../../utils/urls";
import { Spacer } from "../../components/spacer";

const EvaluateButton = ({ record, userId, ...props }) => {
    const apiUrl = getApiUrl();
    const notify = useNotify()

    const refresh = useRefresh()

    const [dialogOpen, setDialogOpen] = useState(false)
    const fields = {
        grade: record.workload ? record.workload : record?.type?.reference_workload,
        finalize: false,
        teacher_observations: ''
    };

    const validateRequired = [required("Campo obrigatório")]
    const canEvaluate = record?.process?.done_by?.find(user => user.id === userId);
    const evaluated = (record?.workload || record?.teacher_observations);
    const reference_workload = record?.type?.reference_workload;


    const openDialog = () => setDialogOpen(true)
    const closeDialog = () => setDialogOpen(false);

    const translateWorkload = (w) => {
        if (w === 'G') return 'Nota'
        else if (w === 'T') return 'Carga horária'
        else if (w === 'C') return 'Cumprimento de tarefa'
    }

    const handleSubmit = async (values) => {
        const token = cookiesProvider.getToken();
        await axios.post(`${apiUrl}/activities/${values.id}/evaluate`,
            { id: values.id, grade: values.grade, teacher_observations: values.teacher_observations, finalize: values.finalize },
            { headers: { "Authorization": token } })
            .then(response => {
                if (response.status === 500) {
                    notify('Erro', 'error')
                } else {
                    notify('Avaliação realizada com sucesso', 'success')
                    refresh()
                }
            })
            .catch((error) => {
                notify(error, 'error')
            });
        setDialogOpen(false);
    }

    const handleUndo = async () => {
        const token = cookiesProvider.getToken();
        await axios.get(`${apiUrl}/activities/${record.id}/undoEvaluate`,
            { headers: { "Authorization": token } })
            .then(response => {
                if (response.status === 500) {
                    notify('Erro', 'error')
                } else {
                    notify('Avaliação desfeita com sucesso', 'success')
                    refresh()
                }
            })
            .catch((error) => {
                notify(error, 'error')
            });
        setDialogOpen(false);
    }

    const ToolbarForm = (props) => {
        return <Toolbar {...props} >
            <MuiButton
                style={{ marginLeft: '5px' }}
                variant={'contained'}
                onClick={closeDialog}
                size={'small'}>
                Cancelar
            </MuiButton>
            <Spacer />
            <MuiButton
                style={{ marginRight: '5px' }}
                variant={'contained'}
                startIcon={<Delete />}
                disabled={!evaluated}
                onClick={handleUndo}
                size={'small'}>
                Desfazer avaliação
            </MuiButton>
            <SaveButton
                label="Salvar"
                submitOnEnter={false}
                onSave={(values) => handleSubmit(values)}
                size={'small'}
            />
        </Toolbar>
    }

    const choices = [{ id: 'ct', name: 'Cumpriu totalmente' }, { id: 'cp', name: 'Cumpriu parcialmente' }, { id: 'nc', name: 'Não cumpriu' }]

    return (
        <>
            {
                canEvaluate && <Button
                    startIcon={<RateReview />}
                    size='small'
                    label={evaluated ? "Revisar avaliação" : "Avaliar"}
                    onClick={openDialog}
                />
            }
            <Dialog open={dialogOpen} onClose={closeDialog} fullWidth>
                <DialogTitle> {evaluated ? "Revisar avaliação" : "Avaliar movimentação"} </DialogTitle>
                <DialogContent dividers>
                    <List dense={true} subheader={<ListSubheader disableSticky>Alunos</ListSubheader>}>
                        {record.users?.map((user, index) => {
                            return (
                                <ListItem key={index}>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <Person />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={user.name}
                                        secondary={user.email}
                                    />
                                </ListItem>
                            )
                        })}
                    </List>
                    <SimpleForm toolbar={<ToolbarForm />} initialValues={fields}>
                        {
                            (record?.type?.workload_type === 'G' || record?.type?.workload_type === 'T') && <TextInput defaultValue={reference_workload} source='grade' label={translateWorkload(record?.type?.workload_type)} validate={validateRequired} fullWidth />
                        }

                        {
                            record?.type?.workload_type === 'C' && <SelectInput source='grade' label='Nota' choices={choices} value={record.workload} optionText='name' validate={validateRequired} fullWidth />
                        }
                        <RichTextInput source='teacher_observations' label='Observações' fullWidth />
                        {!record.evaluated && <BooleanInput source='finalize' label='Finalizar movimentação após correção' fullWidth />}
                    </SimpleForm>
                </DialogContent>
            </Dialog>
        </>
    )
}


export default EvaluateButton