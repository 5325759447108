import { Divider, Grid, Paper, Tab, Tabs } from '@material-ui/core';
import React, { useState } from 'react';
import { Title, useAuthenticated } from 'react-admin';
import SchoolTeacherDashboardActivities from './teacher-dashboard-activities.component';
import SchoolTeacherDashboardPendingGrades from './teacher-dashboard-pending-grades.component';
import { TutorialDialog } from '../../tutorial-dialog';
import SchoolTeacherDashboardAIGrades from './teacher-dashboard-ai-grades.component';


const Panel = (props) => {
    const { children, value, index, ...rest } = props;
    return (
        <div hidden={value !== index} {...rest} >
            {children}
        </div>
    )
}

const SchoolTeacherDashboard = ({ tutorialComplete, ...props }) => {

    const [val, setVal] = useState(0)

    useAuthenticated()

    const handleTabChange = (event, value) => {
        setVal(value);
    }

    return (
        <Grid container spacing={1} style={{ marginTop: '16px' }}>
            <Paper style={{ width: '100%' }}>
                <Title title={'Dashboard do professor'} />
                <Grid item xs={12}>
                    <Tabs value={val} onChange={handleTabChange} centered>
                        <Tab label={'Minhas atividades'} key={'dashboard-activities'} />
                        <Tab label={'Correções'} key={'dashboard-pending-grades'} />
                        <Tab label={'Correções assistidas por IA'} key={'dashboard-ai-grades'} />
                    </Tabs>
                </Grid>
                <Divider />
                <Panel value={val} index={0}>
                    <SchoolTeacherDashboardActivities />
                </Panel>
                <Panel value={val} index={1}>
                    <SchoolTeacherDashboardPendingGrades />
                </Panel>
                <Panel value={val} index={2}>
                    <SchoolTeacherDashboardAIGrades />
                </Panel>
            </Paper>
            <TutorialDialog tutorialComplete={tutorialComplete} />
        </Grid >
    )
}

export default SchoolTeacherDashboard;