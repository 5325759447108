import React from "react";
import { AutocompleteInput, Create, FormTab, ReferenceInput, required, TabbedForm, TextInput } from "react-admin";

const InstitutionBranchCreate = (props) => {

    return (
        <Create {...props}>
            <TabbedForm redirect='list' mutationMode="pessimistic">
                <FormTab label={'Dados Gerais'}>
                    <ReferenceInput source='institution' reference="institutions" validate={[required()]} fullWidth>
                        <AutocompleteInput source="name" optionValue="id" />
                    </ReferenceInput>
                    <TextInput source="name" label="Nome" fullWidth validate={[required()]} />
                </FormTab>
            </TabbedForm>
        </Create>
    )
}
export default InstitutionBranchCreate;