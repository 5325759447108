import RichTextInput from 'ra-input-rich-text';
import React from "react";
import { ArrayInput, BooleanInput, Create, FormDataConsumer, FormTab, required, SelectInput, SimpleFormIterator, TabbedForm, TextInput } from "react-admin";
import { VideoUrlInput } from '../../components/video-url-input';

const ActivityTypeCreate = (props) =>
    <Create {...props}>
        <TabbedForm>
            <FormTab label={'Dados Gerais'} >
                <TextInput source="name" label={'Nome'} validate={[required()]} fullWidth />
                <RichTextInput source="observations" label={'Orientações'} fullWidth />
                <SelectInput
                    label={'Tipo de avaliação'}
                    source="workload_type"
                    choices={[{ id: 'C', name: 'Cumprimento de atividade' }, { id: 'T', name: 'Carga horária' }, { id: 'G', name: 'Nota' }]}
                    validate={[required()]}
                    fullWidth
                />
                <FormDataConsumer>
                    {
                        ({ formData, record, ...rest }) => {
                            if (formData.workload_type === 'T') {
                                return <TextInput source="reference_workload" label={'Carga horária de referência'} step={1} min={0} fullWidth />
                            } else if (formData.workload_type === 'G') {
                                return <TextInput source="reference_workload" label={'Nota de referência'} step={1} min={0} fullWidth />
                            } else {
                                return <></>
                            }
                        }
                    }
                </FormDataConsumer>
                <BooleanInput source="is_hearing" label={'É audiência?'} />
                <FormDataConsumer>
                    {
                        ({ formData, record, ...rest }) => { return !formData.is_hearing && <BooleanInput source="requires_videoconference" label={'Requer videoconferência'} /> }
                    }
                </FormDataConsumer>
            </FormTab>
            <FormTab label={"Vídeos"}>
                <ArrayInput label="Vídeos" source="links">
                    <SimpleFormIterator>
                        <TextInput source="title" label="Título" validate={[required()]} fullWidth />
                        <VideoUrlInput source="url" label="URL" validate={[required()]} fullWidth />
                    </SimpleFormIterator>
                </ArrayInput>
            </FormTab>
        </TabbedForm>
    </Create>

export default ActivityTypeCreate;