import React from "react";
import { BooleanInput, Create, FormDataConsumer, FormTab, required, TabbedForm, TextInput, useNotify, useRedirect, useRefresh } from "react-admin";
import MaskedInput from "../../components/masked-input";

const ClientCreate = (props) => {

    const redirect = useRedirect()
    const notify = useNotify()
    const refresh = useRefresh()

    const onSuccess = ({ data }) => {
        notify('Cliente criado com sucesso')
        redirect('/service-requests/create/general', undefined, {}, {}, { data })
        refresh()
    }

    return (
        <Create {...props} onSuccess={onSuccess}>
            <TabbedForm >
                <FormTab label={'Dados Gerais'} >
                    <TextInput source="name" label={'Nome'} fullWidth />
                    <FormDataConsumer>
                        {({ formData }) => {
                            return !formData?.no_contact_info && <>
                                <MaskedInput label={'Telefone'} source="phone" onlyNumbers={true} mask={'(99) 99999-9999'} validate={[required()]} fullWidth autoComplete="off" />
                                <TextInput label={'Email'} source="email" fullWidth />
                            </>
                        }}
                    </FormDataConsumer>
                    <BooleanInput source='no_contact_info' label={'Contato não informado'} defaultValue={false} fullWidth />
                </FormTab>
            </TabbedForm>
        </Create >
    )
}

export default ClientCreate;