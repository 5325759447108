import { Apartment } from "@material-ui/icons";
import ApplicationResource from "../../utils/resources.enum";
import InstitutionBranchCreate from "./institution-branch.create";
import InstitutionBranchEdit from "./institution-branch.edit";
import InstitutionBranchList from "./institution-branch.list";

export const institutionBranchResource = {
    name: ApplicationResource.INSTITUTION_BRANCHES,
    icon: Apartment,
    options: { label: "Unidades" },
    link: "/institution-branches",
    tableName: 'institution-branches',
    edit: InstitutionBranchEdit,
    create: InstitutionBranchCreate,
    list: InstitutionBranchList,
    products: ['school', 'office']
}